import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import urlJoin from 'url-join';

import { OPS_API_URL } from '../const';
import { executeRequest } from '../client/utils';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, Padding, TitleContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { StoreResellerRequest } from '../container/Registration.container';
import RadioButton from '../components/RadioButton';
import { getOperatingSystem } from '../utils/operatingSystem';

const QuestionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;

    font-weight: 700;
    font-size: 14px;

    padding: 6px 16px;
`;

const RadioContainer = styled.div`
    display: flex;
    text-align: left;
    padding: 10px 16px;
`;

const Form = styled.form``;

interface Props {
    storeReseller: StoreResellerRequest;
    questionNumber: number;
    resellerId?: string;
}

const responseOther = 'Outro';
const questions = [
    {
        title: 'Como você conheceu a Vendah?',
        type: 'radio',
        answers: [
            'Indicação de outra revendedora',
            'Vi divulgação de produtos de revendedora Vendah',
            'Vi anúncio no Instagram/Facebook',
            'Pesquisei no Google',
            'Vi anúncio navegando em sites/notícias/Youtube',
            'Achei na Loja de Aplicativos',
            responseOther,
        ],
        extraFieldKey: 'source',
    },
];

const deviceDetected = getOperatingSystem();
if (!deviceDetected) {
    questions.unshift({
        title: 'Qual tipo de celular você usa?',
        type: 'radio',
        answers: ['Android (Samsung, Motorola, Asus entre outros)', 'iPhone (Apple)'],
        extraFieldKey: 'device_type',
    });
}

function RegistrationQuestionPage({ storeReseller, questionNumber, resellerId }: Props) {
    const [error, setError] = React.useState<Error>();
    const [answers, setAnswers] = React.useState<string[]>([]);
    const [otherDescription, setOtherDescription] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    const submitEnabled = answers.includes(responseOther) ? otherDescription.length > 0 : answers.length > 0;
    const question = questions[questionNumber - 1];
    const firstQuestion = questionNumber === 1;
    const lastQuestion = questionNumber === questions.length;

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (!submitEnabled) return;

        const extraFields = storeReseller.extra_fields;
        extraFields[question.extraFieldKey] = answers.filter(selected => selected !== responseOther);

        if (answers.includes(responseOther)) {
            extraFields[question.extraFieldKey].push(otherDescription);
        }

        const data: StoreResellerRequest = {
            ...storeReseller,
            extra_fields: extraFields,
            vendah_source: 'web',
        };

        if (firstQuestion) {
            data.operating_system =
                deviceDetected ?? (extraFields[question.extraFieldKey][0].startsWith('Android') ? 'android' : 'ios');
        }

        if (lastQuestion) {
            try {
                setLoading(true);
                const storeResellerResponse = await executeRequest(
                    urlJoin(OPS_API_URL, 'store', 'reseller', resellerId ?? ''),
                    {
                        method: resellerId ? 'PUT' : 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    },
                    { ignoreStatus: true }
                );

                setLoading(false);

                gtag('event', 'sign_up');

                if (storeResellerResponse.success) {
                    switch (storeResellerResponse.state) {
                        case 'DISABLED':
                        case 'INVITED':
                            navigate('/mensagem', { state: { messageType: storeResellerResponse.state } });
                            break;
                        default:
                            setError(new Error());
                            navigate('/mensagem', {
                                state: {
                                    messageType: 'INFO',
                                    infoMessage: `Cadastro com status inválido: ${storeResellerResponse.state}`,
                                },
                            });
                    }
                } else {
                    navigate('/mensagem', { state: { messageType: 'INFO', infoMessage: storeResellerResponse.error } });
                }
            } catch (error: any) {
                Sentry.captureException(error);
                setLoading(false);
                setError(error);
            }
        } else {
            navigate('/cadastro/pergunta', {
                state: {
                    storeReseller: data,
                    questionNumber: questionNumber + 1,
                    resellerId,
                },
            });
        }
    }

    if (error) throw error;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Perguntas</Title>
            </TitleContainer>
            <QuestionContainer>
                <p>{question.title}</p>
            </QuestionContainer>

            <Form onSubmit={submitForm}>
                {question.answers.map((item, index) => (
                    <RadioContainer key={index}>
                        <RadioButton
                            key={index}
                            label={item}
                            checked={answers[0] === item}
                            onChange={() => {
                                setAnswers([item]);
                            }}
                        />
                    </RadioContainer>
                ))}

                {answers.includes(responseOther) && (
                    <InputsContainer>
                        <Input
                            id="otherDescription"
                            value={otherDescription}
                            onValueChange={setOtherDescription}
                            label={responseOther}
                            placeholder="Digite sua resposta"
                        />
                    </InputsContainer>
                )}

                <Padding />
                <SubmitButton disabled={!submitEnabled || isLoading}>
                    <Title>{lastQuestion ? 'Cadastrar' : 'Avançar'}</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default RegistrationQuestionPage;
