import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type ResellerReponse = {
    success: boolean;
    reseller_id: string;
    first_name?: string;
    custom_name: string;
    can_ship_to_reseller: boolean;
};

type ResellerPhoneFreeResponse = {
    availability: 'FREE';
};

type ResellerPhoneOthersResponse = {
    availability: 'IN_USE' | 'BLOCKED';
    reseller_id: string;
    first_name: string;
};

export type ResellerPhoneResponse = ResellerReponse & (ResellerPhoneFreeResponse | ResellerPhoneOthersResponse);

export async function getResellerByPhone(clearResellerPhone: string): Promise<ResellerPhoneResponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'store', 'reseller', 'phone', clearResellerPhone),
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        { ignoreStatus: true }
    );
}

export async function findConectahReseller(): Promise<ResellerReponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'reseller', 'custom-name'),
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        { ignoreStatus: true }
    );
}

export type CouponResponse = {
    success: boolean;
    status_code: number;
    error?: string;
    code: string;
    amount: number;
    cancel_commission: boolean;
    min_total_price?: number;
};

export async function getResellerCoupon(resellerId: string, couponCode: string): Promise<CouponResponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'reseller', resellerId, 'coupon', couponCode),
        {},
        { ignoreStatus: true }
    );
}
