import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { logoff, getCustomerName } from '../client/vendah/orderPaymentService/draw-ticket';
import { useStoreReseller } from '../hook/useStoreReseller';
import { Padding } from '../screens/styles';

const CustomerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 20px 10px 35px 10px;
`;

const CustomerDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
`;

function DrawTicketCustomerDetails() {
    const reseller = useStoreReseller();
    const customerName = getCustomerName();
    const navigate = useNavigate();

    function exit() {
        logoff();
        navigate('/cupom-premiado/comprar');
    }

    return (
        <CustomerContainer>
            <b>ÁREA DO CLIENTE</b>
            {reseller && <span>Revendedora: {reseller.fullName}</span>}
            {customerName && (
                <CustomerDetailsContainer>
                    <span>
                        Olá <b>{customerName}</b>,
                    </span>
                    <span>Vamos comprar mais cupons?</span>
                    <span>
                        Não é você?{' '}
                        <a href="#sair" onClick={exit}>
                            Sair
                        </a>
                    </span>
                </CustomerDetailsContainer>
            )}
        </CustomerContainer>
    );
}
export default DrawTicketCustomerDetails;
