import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import urlJoin from 'url-join';

import { OPS_API_URL } from '../const';
import { isEmailValid } from '../utils/format';
import { executeRequest } from '../client/utils';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, TitleContainer } from './styles';
import { useLocalStorageState } from '../utils/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { StoreResellerRequest } from '../container/Registration.container';

const Form = styled.form``;

interface Props {
    storeReseller: Partial<StoreResellerRequest>;
}

function RegistrationEmailPage({ storeReseller }: Props) {
    const [email, setEmail] = useLocalStorageState('register.email');
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    const emailInvalid = React.useMemo(() => !isEmailValid(email), [email]);
    const submitDisabled = emailInvalid;

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (submitDisabled) return;

        setLoading(true);

        try {
            const customerState = await executeRequest(urlJoin(OPS_API_URL, 'customer-state', email));

            switch (customerState.state) {
                case 'NOT_FOUND':
                case 'DISABLED':
                    navigate('/cadastro/dados', {
                        state: { storeReseller: { ...storeReseller, email }, resellerId: customerState.resellerId },
                    });
                    setLoading(false);
                    break;
                case 'INVITED':
                case 'ENABLED':
                case 'DISABLED_MANUAL':
                case 'BLOCKED':
                case 'ERASED':
                    // report a sign up for paid media, since the registration flow has been completed
                    gtag('event', 'sign_up');
                    navigate('/mensagem', {
                        state: { storeReseller: { ...storeReseller, email }, messageType: customerState.state },
                    });
                    setLoading(false);
                    break;
                default:
                    navigate('/mensagem', {
                        state: {
                            messageType: 'INFO',
                            infoMessage: `Status do cliente inválido: ${customerState.state}`,
                        },
                    });
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setLoading(false);
            navigate('/mensagem', { state: { messageType: 'INFO', infoMessage: 'Erro ao consultar E-mail' } });
        }
    }

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>E-mail</Title>
            </TitleContainer>

            <Form onSubmit={submitForm}>
                <InputsContainer>
                    <Input
                        id="email"
                        value={email}
                        label="E-mail"
                        onValueChange={text => setEmail(text.trim())}
                        placeholder="E-mail"
                        invalidMessage={emailInvalid ? 'E-mail inválido' : undefined}
                    />
                </InputsContainer>
                <SubmitButton disabled={submitDisabled || loading}>
                    <Title>Avançar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default RegistrationEmailPage;
