/* eslint-disable import/first */
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://9077bc13bf2a434b8d68a20a9ebc7efd@o1253920.ingest.sentry.io/6421628',
    integrations: [
        new BrowserTracing({
            tracingOrigins: [
                'localhost',
                /^\//,
                'vendah.de',
                'vendah.com.br',
                'pedido.vendah.com.br',
                'web.vendah.com.br',
            ],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],
    tracesSampleRate: 0.05,
});
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './screens/Error.page';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
