import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type StoreResellerResponse = {
    success: boolean;
    fullName: string;
};

export async function getStoreReseller(resellerId: string): Promise<StoreResellerResponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'store', 'reseller', resellerId),
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
        { ignoreStatus: true }
    );
}
