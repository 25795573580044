import * as React from 'react';
import * as Sentry from '@sentry/react';

import { LogoContainer, Spinner, Title, VendahLogo } from '../components';
import { Container, Padding, TitleContainer } from './styles';
import { useAsyncEffect } from '../hook/useAsyncEffect';
import { executeRequest } from '../client/utils';
import urlJoin from 'url-join';
import { OPS_API_URL } from '../const';
import styled from 'styled-components';
import { DrawTickets, getDrawTickets } from '../client/vendah/orderPaymentService/draw-ticket';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionButton } from '../components/Button';
import DrawTicketCustomerDetails from '../components/DrawTicketCustomerDetails';

export type PrizeDrawConfig = {
    data: {
        prizeDraws: PrizeDraw[];
    };
};

export type PrizeDraw = {
    title: string;
    shopStartDate: string;
    shopEndDate: string;
};

const VouchersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 16px;
`;

const VoucherContainer = styled.div`
    align-items: center;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.12);
    padding: 0px 10px;
`;

function DrawTicketTicketsPage() {
    const [prizeDrawConfig, setPrizeDrawConfig] = React.useState<PrizeDrawConfig>();
    const [drawTickets, setDrawTickets] = React.useState<DrawTickets>();
    const [error, setError] = React.useState<Error>();

    const [searchParams] = useSearchParams();
    const paid = searchParams.get('paid');

    const navigate = useNavigate();

    useAsyncEffect(
        async () => {
            try {
                const config: PrizeDrawConfig = await executeRequest(urlJoin(OPS_API_URL, 'config', 'prizeDraws'));

                console.log('config', config);
                return config;
            } catch (error) {
                setError(new Error('Erro ao consultar sorteios!'));
            }
        },
        setPrizeDrawConfig,
        []
    );

    const currentSellingPrizeDraw = React.useMemo(() => getCurrentSellingPrizeDraw(prizeDrawConfig), [prizeDrawConfig]);

    useAsyncEffect(
        async () => {
            if (!currentSellingPrizeDraw) return undefined;

            try {
                const response: DrawTickets = await getDrawTickets(
                    new Date(currentSellingPrizeDraw.shopEndDate),
                    navigate
                );

                gtag('event', 'draw_ticket_lucky_numbers', { lucky_number: response });
                return response;
            } catch (error) {
                setError(new Error('Erro ao consultar os números da sorte!'));
                Sentry.captureException(error);
            }
        },
        setDrawTickets,
        [currentSellingPrizeDraw]
    );

    function getCurrentSellingPrizeDraw(prizeDrawConfig?: PrizeDrawConfig) {
        if (!prizeDrawConfig) return undefined;

        return prizeDrawConfig.data.prizeDraws.find(
            prizeDraw => new Date() > new Date(prizeDraw.shopStartDate) && new Date() < new Date(prizeDraw.shopEndDate)
        );
    }

    function navigateToShop() {
        navigate('/cupom-premiado/comprar');
    }

    if (error) throw error;

    if (!drawTickets) return <Spinner />;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <TitleContainer>
                <Title>Cupom Premiado</Title>
            </TitleContainer>
            <DrawTicketCustomerDetails />
            <strong>{currentSellingPrizeDraw?.title}</strong>

            {paid === '1' && <p>Pagamento efetuado com sucesso. Em breve seus números da sorte estarão aqui</p>}
            {paid !== '1' && drawTickets.drawTickets.length === 0 && (
                <p>Você ainda não possui nenhum número da sorte</p>
            )}
            {drawTickets.drawTickets.length > 0 && (
                <>
                    <p>Você está concorrendo</p>
                    <VouchersContainer>
                        {drawTickets.drawTickets.map((item, index) => (
                            <>
                                <VoucherContainer>
                                    <p>
                                        Número ds sorte:{' '}
                                        <strong>#{item.monthLuckyNumber.split('-')[1].padStart(6, '0')}</strong>
                                    </p>
                                    <p>Cupom: {item.coupon}</p>
                                </VoucherContainer>
                                <Padding />
                            </>
                        ))}
                    </VouchersContainer>
                </>
            )}
            {drawTickets.drawTickets.length < 10 && (
                <ActionButton onPress={() => navigateToShop()}>
                    <Title>Comprar</Title>
                </ActionButton>
            )}
        </Container>
    );
}

export default DrawTicketTicketsPage;
