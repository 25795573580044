import * as React from 'react';

import { useAsyncEffect } from '../useAsyncEffect';
import { getResellerId } from '../../client/vendah/orderPaymentService/draw-ticket';
import { getStoreReseller, StoreResellerResponse } from '../../client/vendah/orderPaymentService/store-reseller';

export function useStoreReseller() {
    const [reseller, setReseller] = React.useState<StoreResellerResponse>();
    useAsyncEffect(
        async () => {
            try {
                const resellerId = getResellerId();
                if (!resellerId) return undefined;
                return await getStoreReseller(resellerId);
            } catch (error: any) {
                console.error('error getting reseller', error);
                return undefined;
            }
        },
        setReseller,
        [getResellerId()]
    );
    return reseller;
}
