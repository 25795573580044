import * as React from 'react';
import { useParams } from 'react-router-dom';
import urlJoin from 'url-join';
import Spinner from '../components/Spinner';
import { OPS_API_URL } from '../const';
import CancelledPage from '../screens/Cancelled.page';
import ExpiredPage from '../screens/Expired.page';
import PaidPage from '../screens/Paid.page';
import PendingPage from '../screens/Pending.page';

export enum OrderStatus {
    Pending = 'PENDING',
    Paid = 'PAID',
    Shipped = 'SHIPPED',
    Cancelled = 'CANCELLED',
    Expired = 'EXPIRED',
}

export type LineItem = {
    name: string;
    quantity: number;
    image?: {
        url: string;
        altText?: string;
    };
};

export type Order = {
    order_id: number;
    hash: string;
    order_name: string;
    cancelled_at?: string;
    customer_name: string;
    reseller: {
        name: string;
        phone?: string;
    };
    payment_url: string;
    shipping_address: {
        address1: string;
        address2?: string;
        phone?: string;
        zip: string;
        city: string;
        province_code: string;
    };
    shipping_type?: string;
    status: OrderStatus;
    total_price: string;
    line_items: LineItem[];
};

export const OrderContext = React.createContext(undefined) as React.Context<Order | undefined>;

function OrderContainer(): JSX.Element | null {
    const { orderHash } = useParams();
    const [order, setOrder] = React.useState<Order>();
    const [error, setError] = React.useState<Error>();

    // Get order from OPS
    React.useEffect(() => {
        if (!order && !error) {
            const fetchOrder = async () => {
                const response = await fetch(urlJoin(OPS_API_URL, 'orders', orderHash!));
                if (response.status === 404) {
                    setError(new Error('Pedido não encontrado!', { cause: response as any }));
                } else if (response.status >= 400) {
                    setError(new Error('Erro ao procurar pedido!', { cause: response as any }));
                } else {
                    const order = await response.json();
                    gtag('event', 'get-order', { order });
                    setOrder(order);
                }
            };

            fetchOrder().catch(error => {
                console.error(error);
                setError(error);
            });
        }
    }, [order, orderHash, error]);

    if (error) throw error;

    if (!order) return <Spinner />;

    let Page = null;
    if (order.status === OrderStatus.Expired) {
        Page = <ExpiredPage />;
    } else if (order.status === OrderStatus.Cancelled) {
        Page = <CancelledPage />;
    } else if (order.status === OrderStatus.Paid || order.status === OrderStatus.Shipped) {
        Page = <PaidPage />;
    } else if (order.status === OrderStatus.Pending) {
        Page = <PendingPage />;
    }

    return <OrderContext.Provider value={order}>{Page}</OrderContext.Provider>;
}

export default OrderContainer;
