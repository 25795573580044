import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { formatPhone, removeTrailingNonDigits } from '../utils/format';
import { Input, LogoContainer, SubmitButton, Title, VendahLogo } from '../components';
import { Container, InputsContainer, TitleContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { isPhoneValid } from '../utils/validation/phone';
import { sendVerificationCode } from '../client/vendah/orderPaymentService/draw-ticket';
import { useLocalStorageState } from '../utils/useLocalStorage';
import DrawTicketCustomerDetails from '../components/DrawTicketCustomerDetails';

const Form = styled.form``;

interface Props {
    callback: string;
}

function DrawTicketPhonePage({ callback }: Props) {
    const [phone, setPhone] = useLocalStorageState('draw.ticket.phone');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    const phoneInvalid = React.useMemo(() => !isPhoneValid(phone), [phone]);

    const navigate = useNavigate();

    const submitDisabled = phoneInvalid;

    async function submitForm(evt: any) {
        evt.preventDefault();
        if (submitDisabled) return;

        setLoading(true);

        try {
            const response = await sendVerificationCode(phone);
            console.log('checking phone response', response);

            gtag('event', 'draw_ticket_phone_submit');

            if (response.success) {
                navigate('/cupom-premiado/codigo', { state: { phone, callback } });
            } else {
                setError(new Error('Erro ao consultar o cliente!', { cause: response as any }));
            }
        } catch (error: any) {
            Sentry.captureException(error);
            setLoading(false);
            setError(error);
        }
    }

    if (error) throw error;

    return (
        <Container>
            <LogoContainer>
                <VendahLogo />
            </LogoContainer>
            <DrawTicketCustomerDetails />
            <TitleContainer>
                <Title>Insira seu celular</Title>
            </TitleContainer>
            <p>Bem-vindo ao Cupom Premiado!</p>
            <p>Para se cadastrar ou entrar, insira seu celular</p>

            <Form onSubmit={submitForm}>
                <InputsContainer>
                    <Input
                        id="phone"
                        value={phone}
                        label="Celular"
                        placeholder="(11) 99999-9999"
                        onValueChange={text => {
                            setPhone(removeTrailingNonDigits(formatPhone(text)));
                        }}
                        invalidMessage={phoneInvalid ? 'Celular inválido' : undefined}
                    />
                </InputsContainer>
                <SubmitButton disabled={submitDisabled || loading}>
                    <Title>Avançar</Title>
                </SubmitButton>
            </Form>
        </Container>
    );
}

export default DrawTicketPhonePage;
