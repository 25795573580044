import styled from 'styled-components';
import { ReactComponent as CancelSvg } from './cancel.svg';

const CancelView = styled.div`
    display: flex;
`;

function Cancel() {
    return (
        <CancelView>
            <CancelSvg />
        </CancelView>
    );
}

export default Cancel;
