import urlJoin from 'url-join';
import { OPS_API_URL } from '../../../const';
import { executeRequest } from '../../utils';

export type CreateOrderRequest = {
    seller_id: number; // reseller_id
    customer_first_name: string;
    customer_last_name: string;
    customer_tax_id?: string;
    customer_phone?: string;
    ship_to_seller: boolean;
    shipping_address?: AddressRequest;
    line_items: LineItem[];
    need_to_set_address: boolean;
    discount?: Discount;
    shipping_fee?: number;
    auto_checkout: boolean;
    tags?: string;
    shipment_workdays?: number;
    coupon?: Coupon;
};

type Coupon = {
    code: string;
    amount: number;
};

export type LineItem = {
    variant_id: number;
    quantity: number;
    unit_price: number; // this is unit reseller cost (before commission and discounts)
    unit_discount: number;
    total_commission: number;
    discount_name?: string;
};

export type Discount = {
    name: string;
    amount: number;
};

export type CreateOrderResponse = {
    success: boolean;
    status_code: number;
    error?: string;
    errorCode?: string;
    order_name: string;
    order_id: number;
    payment_url?: string;
    url: string;
    hash: string;
};

type AddressRequest = {
    postal_code: string;
    street_address: string;
    street_number: string;
    complement: string;
    reference: string;
    neighborhood: string;
    city: string;
    state_code: string;
    phone: string;
};

export async function createOrder(request: CreateOrderRequest): Promise<CreateOrderResponse> {
    return await executeRequest(
        urlJoin(OPS_API_URL, 'orders'),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        },
        { ignoreStatus: true }
    );
}
